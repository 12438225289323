(function ($) {
  Drupal.behaviors.tabbedBlockV2 = {
    attach: function (context) {
      var $modules = $('.js-tabbed-block--v2', context);

      $modules.each(function () {
        var $module = $(this);
        var $overlay = $('.js-tabbed-block__overlay', $module);
        var $header = $('.js-tabbed-block__header', $module);
        var $tabs = $('.js-tabbed-block__tabs', $module);
        var $tab = $('.js-tabbed-block__tab', $module);
        var $pane = $('.js-tabbed-block__pane', $module);
        var $closeTab = $('.js-tabbed-block__overlay, .js-tabbed-block__close-icon', $module);
        var $headerPlaceholder = $('.js-tabbed-block-header-placeholder', $module);
        var $tabbedBlockSubheader = $headerPlaceholder.find('.js-tabbed-block__subheader');

        if ($header.length > 0) {
          $module.addClass('has-header');
        }

        function switchToIndex(index = 0) {
          var $activePane = $pane.eq(index);

          // Show this tab content
          $pane.removeClass('selected');
          $activePane.addClass('selected');
          $tab.removeClass('selected');
          $tab.eq(index).addClass('selected');
          $(document).trigger('animations.waypointsRefresh');
          if ($module.hasClass('dropdown-align-center')) {
            $tabbedBlockSubheader.removeClass('active');
            $tabbedBlockSubheader.eq(index).addClass('active');
          }
        }

        $tab.off('click.tabbedBlockV2').on('click.tabbedBlockV2', function (event) {
          event.preventDefault();
          var index = $(this).index();

          $overlay.toggleClass('open');
          $tabs.toggleClass('open');

          switchToIndex(index);
        });

        $closeTab.on('click', function () {
          $overlay.removeClass('open');
          $tabs.removeClass('open');
        });
      });
    }
  };
})(jQuery);
